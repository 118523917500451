import React, { ReactElement, useState } from 'react';

import {
  Container,
  ContentGroup,
  GridItem,
  Spinner,
} from '@constellation/core';
import { useContent } from '@interstellar/react-app-content';
import { useNavigate } from '@interstellar/react-app-routing';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from 'styled-components';

import {
  ForgotPasswordContent,
  SetupPasswordProps,
} from './forgotPassword.config';
import { wssUpdatePasswordApi } from '../../apis/wssUpdatePasswordApi';
import SuccessIcon from '../../assets/icons/successIcon';
import DataModal from '../../components/dataModal/dataModal';
import SetPassword from '../../components/setPassword/setPassword';
import dataQaIds from '../../dataModel/dataQaIds';
import { RootState } from '../../store';
import { DataModelAction } from '../../store/action/dataModal.action';
import {
  OldPasswordFailureAction,
  PasswordFailureAction,
} from '../../store/action/registration.action';
import { isEmptyString } from '../../utils/isEmptyString';
import * as routes from '../manifest';
import RegistrationModal from '../registration/registrationModal';

export default function SetupPassword({
  isForgotPassword,
  mtaCustomerId,
  brand,
  emailAddress,
  wssCustomerId,
  handleBackToResendPasscode,
  oldPassword,
}: SetupPasswordProps): ReactElement {
  const theme: any = useTheme();
  const {
    dataModelPasswordHeading,
    dataModelPasswordText,
    forgotEmailDataModalButtonText,
    oldPassowordApiMessage,
    authliteApiErrorMessage,
    authlitePasswordErrorMessage,
  } = useContent<ForgotPasswordContent>();
  const [password, setPassword] = useState('');
  const [oldPasswordFieldErrMsg, setOldPasswordFieldErrMsg] = useState('');
  const [commonWordPasswordFieldErrMsg, setCommonWordPasswordFieldErrMsg] =
    useState('');
  const [showSpinner, setShowSpinner] = useState(false);
  const [isApiFailed, setIsApiFailed] = useState(false);
  const handleOnPasswordValue = (value: string): void => setPassword(value);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isAuthZFailed, isOldPassword } = useSelector(
    (state: RootState) => state.RegistrationReducer,
  );
  const handleCloseWarningDialog = (): void => {
    dispatch(DataModelAction(false));
    setIsApiFailed(false);
  };
  const handleOnPasswordData = (data): void => {
    setShowSpinner(false);
    const message = data.data?.Data?.[0]?.errors?.[0]?.message;
    if (message === oldPassowordApiMessage) {
      dispatch(OldPasswordFailureAction(true));
      dispatch(DataModelAction(true));
      setCommonWordPasswordFieldErrMsg('');
      return;
    }
    if (
      data.status === 422 &&
      (data?.data?.StatusMsg === authliteApiErrorMessage ||
        data?.StatusMsg === authliteApiErrorMessage)
    ) {
      dispatch(DataModelAction(false));
      setIsApiFailed(true);
      setOldPasswordFieldErrMsg('');
      dispatch(PasswordFailureAction(''));
      dispatch(OldPasswordFailureAction(false));
      setCommonWordPasswordFieldErrMsg(authlitePasswordErrorMessage);
      return;
    }
    if (data.status === 422) {
      dispatch(DataModelAction(true));
      dispatch(
        PasswordFailureAction(
          isForgotPassword ? 'forgotPassword' : 'setupPassword',
        ),
      );
      setIsApiFailed(true);
      setOldPasswordFieldErrMsg('');
      setCommonWordPasswordFieldErrMsg('');
      dispatch(OldPasswordFailureAction(false));
    } else {
      dispatch(OldPasswordFailureAction(false));
      dispatch(DataModelAction(true));
      setIsApiFailed(true);
    }
  };
  const handleDataObject = () => {
    return {
      wssCustomerId: !isEmptyString(wssCustomerId) ? wssCustomerId : '',
      emailAddress,
      password,
      mtaCustomerId: !isEmptyString(mtaCustomerId) ? mtaCustomerId : '',
      oldPassword: !isEmptyString(oldPassword) ? atob(oldPassword) : '',
    };
  };
  const handleOnApiCall = (passedToken): void => {
    setShowSpinner(true);
    wssUpdatePasswordApi(handleDataObject(), {
      Authorization: `Bearer ${passedToken}`,
    })
      .then(() => {
        setIsApiFailed(false);
        setOldPasswordFieldErrMsg('');
        dispatch(DataModelAction(true));
        setShowSpinner(false);
      })
      .catch((error: any) => {
        setShowSpinner(false);
        handleOnPasswordData(error.response);
      });
  };

  const handleNavigateLogin = (): void => {
    dispatch(DataModelAction(false));
    navigate(routes.WelcomePage);
  };

  const renderSpinner = (): ReactElement => (
    <Container marginBottom="07" marginTop="07" padding="none">
      <ContentGroup marginBottom="07" marginTop="07">
        <Spinner />
      </ContentGroup>
    </Container>
  );

  return (
    <GridItem>
      {showSpinner ? (
        renderSpinner()
      ) : (
        <>
          <SetPassword
            isForgotPassword={isForgotPassword}
            isRegistration={false}
            isLogin={false}
            handleOnClick={handleOnApiCall}
            oldPasswordErrorMessage={oldPasswordFieldErrMsg}
            handleOnPasswordValue={handleOnPasswordValue}
            mtaCustomerId={mtaCustomerId}
            authliteCommmonPasswordErrorMsg={commonWordPasswordFieldErrMsg}
          />
          {!isApiFailed && !isOldPassword && !isAuthZFailed ? (
            <DataModal
              icon={<SuccessIcon colour={theme.color_text_default_1} />}
              onClick={handleNavigateLogin}
              heading={dataModelPasswordHeading}
              description={dataModelPasswordText}
              buttonTxt={forgotEmailDataModalButtonText}
              dataQaId={{
                btn: dataQaIds.loginAndRegistration.loginBtn,
              }}
              brand={brand}
              popupName="New password successfully saved"
            />
          ) : (
            <RegistrationModal
              brand={brand}
              isAgreementFound
              isContactFound
              isCustomerRecordFound
              handleCloseWarningDialog={handleCloseWarningDialog}
              isRegistered={false}
              isApiFailed={isApiFailed}
              isLogin={false}
              handleBackToResendPasscode={handleBackToResendPasscode}
            />
          )}
        </>
      )}
    </GridItem>
  );
}
