import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import {
  Button,
  ContentGroup,
  Grid,
  GridItem,
  Heading,
  Hr,
  Text,
} from '@constellation/core';
import { useContent } from '@interstellar/react-app-content';
import { useNavigate } from '@interstellar/react-app-routing';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from 'styled-components';

import {
  ApiAuthResponse,
  DateObject,
  RegistrationContent,
  RegistrationMTAProps,
} from './Registration.config';
import { StyledMarginButton } from './Registration.styled';
import RegistrationCredentials from './registrationCredentials';
import RegistrationModal from './registrationModal';
import SetupPassword from './setupPassword';
import { authSMSSender } from '../../apis/authSMSSenderApi';
import { confirmAccountAPI } from '../../apis/confirmAccountApi';
import { registrationAPI } from '../../apis/registrationApi';
import SuccessIcon from '../../assets/icons/successIcon';
import {
  StyledBox,
  StyledInlineBox,
} from '../../components/appConfig/common.styled';
import ChangeEmail from '../../components/changeEmail/changeEmail';
import DataModal from '../../components/dataModal/dataModal';
import FormStepper from '../../components/formStepper/FormStepper';
import LinkButton from '../../components/linkButton/LinkButton';
import NavigatePreviousLink from '../../components/navigatePreviousLink';
import NeedHelp from '../../components/needHelp/NeedHelp';
import OTPAuthentication from '../../components/otpAuthentication/otpAuthentication';
import PhoneLink from '../../components/phoneLink/PhoneLink';
import RegistrationAuthenticate from '../../components/registrationAuthenticate/registrationAuthenticate';
import RegistrationYourDetails from '../../components/registrationYourDetails/registrationYourDetails';
import SpinnerContainer from '../../components/spinner/spinnerContainer';
import { FormSubSteps } from '../../constants/formSubSteps';
import dataQaIds from '../../dataModel/dataQaIds';
import { RootState } from '../../store';
import { DataModelAction } from '../../store/action/dataModal.action';
import {
  changeStepAction,
  changeNumberOfStepsAction,
  changeSubStepsAction,
} from '../../store/action/formStep.action';
import {
  AuthZFailureAction,
  CustomerAgreementNumberAction,
  DOBPostcodeContractsDataAction,
  HasRegistrationNumberAction,
  PasswordFailureAction,
  UpdateDOBPostcodeAction,
} from '../../store/action/registration.action';
import { ShowHideSpinnerAction } from '../../store/action/utilsModal.action';
import { dataLayer } from '../../utils/dataLayer';
import { dateFormat } from '../../utils/dateFormat';
import { handleOnPostcodeFormat } from '../../utils/handleOnPostcodeFormat';
import { isEmptyString } from '../../utils/isEmptyString';
import * as routes from '../manifest';

export default function RegistrationMTA({
  brand,
  numberOfSteps,
  setPasswordValue,
  handleOnModalOpen,
  handleIsRegistered,
}: RegistrationMTAProps) {
  const theme: any = useTheme();
  const dispatch = useDispatch();
  const {
    dob,
    postcode,
    agreementNumber,
    hasAllRegistrationNumbers,
    customerData,
    authToken,
    hasPasswordSet,
    constraints,
  } = useSelector((state: RootState) => state.RegistrationReducer);
  const { emailAddress } = useSelector(
    (state: RootState) => state.LoginReducer,
  );
  const utilsStore = useSelector((state: RootState) => state.UtilsReducer);
  const { stepNumber, subSteps } = useSelector(
    (state: RootState) => state.FormStepsReducer,
  );
  const [mtaCustomerId, setMtaCustomerId] = useState('');
  const [wssCustomerID, setWssCustomerID] = useState('');
  const [isRegistered, setIsRegistered] = useState(
    customerData[0].isRegistered,
  );
  const [email, setEmail] = useState(customerData[0].emailAddress);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [isEmailFound, setIsEmailFound] = useState(false);
  const [isContactFound, setIsContactFound] = useState(false);
  const [isAgreementFound, setIsAgreementFound] = useState(true);
  const [isCustomerRecordFound, setIsCustomerRecordFound] = useState(true);
  const [postCode, setPostCode] = useState(postcode);
  const [isContinueClicked, setIsContinueClicked] = useState(false);
  const [isDateField, setIsDateField] = useState(false);
  const [isPostCodeError, setIsPostCodeError] = useState(true);
  const [isDateError, setIsDateError] = useState(true);
  const [isApiCalled, setIsApiCalled] = useState(false);
  const [journeySessionId, setJourneySessionId] = useState('');
  const [expiryTime, setExpiryTime] = useState('');
  const [agreementNo, setAgreementNo] = useState(agreementNumber);
  const isApiFailed = useRef(false);
  const [date, setDate] = useState<DateObject>(
    dob || {
      day: undefined,
      month: undefined,
      year: undefined,
    },
  );

  const {
    credentialsHeading,
    yourDetailsHeading,
    authenticateHeading,
    sendPasscodeLabel,
    apiErrorMessage,
    changeEmailLabel,
    howUseEmailLabel,
    yourDataLabel,
    numberNotRightLabel,
    createYourAccountLabel,
    confirmAndContinueLabel,
    continueLabel,
    dataModalHeading,
    dataModalText,
  } = useContent<RegistrationContent>();

  const initialSubSteps = useMemo(
    () => [
      { description: FormSubSteps.PERSONAL_DETAILS, isActive: true },
      ...(!customerData[0].isWSS
        ? [
            {
              description: FormSubSteps.CONFIRM_EMAIL_ADDRESS,
              isActive: false,
            },
          ]
        : [
            {
              description: FormSubSteps.CHANGE_EMAIL_ADDRESS,
              isActive: false,
            },
          ]),
      { description: FormSubSteps.SEND_OTP, isActive: false },
      { description: FormSubSteps.VERIFY_OTP, isActive: false },
      { description: FormSubSteps.CREATE_PASSWORD, isActive: false },
    ],
    [customerData],
  );
  useEffect(() => {
    if (subSteps.length === 0) {
      dispatch(changeSubStepsAction(initialSubSteps));
    }
  }, [customerData, dispatch, initialSubSteps, subSteps.length]);
  useEffect(() => {
    dispatch(changeNumberOfStepsAction(numberOfSteps));
  }, [customerData, dispatch, numberOfSteps]);

  const getSubStepName = useCallback((): string => {
    return (
      subSteps.length > 0 &&
      subSteps.filter((item) => item.isActive).length > 0 &&
      subSteps.filter((item) => item.isActive)[0].description
    );
  }, [subSteps]);

  const currentSubStep = getSubStepName();

  useEffect(() => {
    if (!isEmptyString(phoneNumber)) setIsContactFound(true);
    else setIsContactFound(false);
  }, [phoneNumber]);

  useEffect(() => {
    const hanldePopState = () => {
      setIsContinueClicked(false);
      setIsPostCodeError(false);
      setIsDateError(false);
      dispatch(changeStepAction(1));
      dispatch(changeSubStepsAction(initialSubSteps));
    };
    window.addEventListener('popstate', hanldePopState);
  }, [dispatch, initialSubSteps]);

  useEffect(() => {
    const specifyMultiAgreementStep = () => {
      if (
        currentSubStep === FormSubSteps.MULTI_AGREEMENT &&
        hasAllRegistrationNumbers
      ) {
        return FormSubSteps.ENTER_REGISTRATION_NUMBER;
      }
      if (
        currentSubStep === FormSubSteps.MULTI_AGREEMENT &&
        !hasAllRegistrationNumbers
      ) {
        return FormSubSteps.ENTER_AGREEMENT_NUMBER;
      }
      return currentSubStep;
    };
    window.appConfig.JOURNEY_NAME = 'Registration';
    window.appConfig.JOURNEY_STEP_NAME = specifyMultiAgreementStep();
    window.appConfig.PAGE_ROLE = 'Servicing';
    dataLayer();
  }, [currentSubStep, getSubStepName, hasAllRegistrationNumbers, subSteps]);
  const isNoEmailFound = (mail: string): boolean => {
    if (isEmptyString(mail)) {
      setIsEmailFound(false);
      return true;
    }
    setIsEmailFound(true);
    return false;
  };
  const handleOnNoContactCondition = (number: string): void => {
    setPhoneNumber(number);
    setIsContactFound(true);
  };
  const handlePassDetails = (
    res: ApiAuthResponse,
    isMultAgreement: boolean,
  ): void => {
    dispatch(
      DOBPostcodeContractsDataAction([res], {
        isDetailsFound: true,
        isApiFailed: false,
        isMoreThanOneUser: isMultAgreement,
      }),
    );
    handleOnNoContactCondition(res.obscuredPhoneNumber);
    setMtaCustomerId(res.mtaCustomerId);
    setWssCustomerID(res.wssCustomerID);
    setEmail(res.emailAddress);
  };

  const handleOnPostCode = (value: string): void => {
    setPostCode(value);
  };
  const resetSubSteps = () => {
    // eslint-disable-next-line no-param-reassign, no-return-assign
    subSteps.forEach((item) => (item.isActive = false));
  };
  const handleOnChangeEmailStep = (value: boolean): void => {
    resetSubSteps();
    if (value === true) {
      subSteps.splice(3, 0, {
        description: FormSubSteps.CHANGE_EMAIL_ADDRESS,
        isActive: true,
      });
    } else {
      subSteps.splice(3, 1);
      subSteps[2].isActive = true;
    }

    dispatch(changeSubStepsAction(subSteps));
  };

  const handleOnSessionId = (value: string): void => {
    setJourneySessionId(value);
  };

  const handleOnDate = (value: DateObject): void => {
    setDate(value);
  };
  const handleOnPwd = (value: string): void => {
    setPasswordValue(value);
  };
  const handleCloseWarningDialog = (): void => {
    dispatch(DataModelAction(false));
    setIsContinueClicked(false);
  };
  const navigate = useNavigate();
  const handleNavigate = (): void => {
    navigate(routes.HelpCentre);
  };
  const handleEncoding = (): string =>
    btoa(`auth_token=${authToken}&a=1&email=${emailAddress}`);
  const handleNavigateLogin = (): void => {
    dispatch(DataModelAction(false));
    if (customerData[0].isWSS) navigate(routes.WelcomePage);
    else navigate(`${routes.Marketingpreferences}?${handleEncoding()}`);
  };

  const handleOnNavigateHomepage = (): void => {
    navigate(routes.WelcomePage);
    dispatch(changeSubStepsAction(initialSubSteps));
  };
  const handleOnPostCodeError = (value: boolean): void =>
    setIsPostCodeError(value);
  const handleOnDateError = (value: boolean): void => setIsDateError(value);
  const renderStepTitle = (): string => {
    if (stepNumber === 1) {
      return yourDetailsHeading;
    }
    if (stepNumber === 2) {
      return credentialsHeading;
    }
    return authenticateHeading;
  };
  const renderContinueButtonLabel = (): string => {
    if (stepNumber === 1) {
      return continueLabel;
    }
    if (stepNumber === 2 && currentSubStep !== FormSubSteps.SEND_OTP) {
      return confirmAndContinueLabel;
    }
    return sendPasscodeLabel;
  };
  const shouldChangeStep = (isPrevious: boolean) => {
    return (
      (stepNumber === 2 &&
        currentSubStep !== FormSubSteps.SEND_OTP &&
        isPrevious) ||
      (stepNumber === 3 &&
        currentSubStep === FormSubSteps.VERIFY_OTP &&
        isPrevious) ||
      (stepNumber === 1 &&
        currentSubStep === FormSubSteps.MULTI_AGREEMENT &&
        !isPrevious) ||
      (stepNumber === 1 &&
        currentSubStep === FormSubSteps.PERSONAL_DETAILS &&
        !isPrevious) ||
      (stepNumber === 2 &&
        currentSubStep === FormSubSteps.SEND_OTP &&
        !isPrevious)
    );
  };

  const setInitialErrorState = () => {
    setIsPostCodeError(false);
    setIsDateError(false);
  };

  const handleStepper = (
    isPrevious,
    isMultAgreement = false,
    isChangeEmailStep = false,
  ) => {
    setInitialErrorState();
    if (
      stepNumber === 1 &&
      currentSubStep === FormSubSteps.PERSONAL_DETAILS &&
      isPrevious
    ) {
      navigate(routes.WelcomePage);
      return;
    }
    if (
      stepNumber === 3 &&
      currentSubStep === FormSubSteps.CREATE_PASSWORD &&
      !isPrevious
    ) {
      return;
    }
    if (
      stepNumber === 2 &&
      currentSubStep === FormSubSteps.CHANGE_EMAIL_ADDRESS &&
      customerData[0].isWSS &&
      constraints.isMoreThanOneUser &&
      isPrevious
    ) {
      subSteps.splice(3, 0, {
        description: FormSubSteps.MULTI_AGREEMENT,
        isActive: true,
      });
      dispatch(changeSubStepsAction(subSteps));
    }
    resetSubSteps();
    const index = subSteps.findIndex(
      (item) => item.description === currentSubStep,
    );
    const newIndex = (i) => (isPrevious ? i - 1 : i + 1);
    subSteps[newIndex(index)].isActive = true;
    if (isChangeEmailStep && !customerData[0].isWSS) {
      subSteps.splice(3, 1);
    }
    dispatch(changeSubStepsAction([...subSteps]));

    if (shouldChangeStep(isPrevious) && !isMultAgreement) {
      dispatch(changeStepAction(newIndex(stepNumber)));
    }
  };
  const handleBackToResendPasscode = () => {
    resetSubSteps();
    subSteps.forEach((item) => {
      if (item.description === FormSubSteps.SEND_OTP) {
        // eslint-disable-next-line no-param-reassign
        item.isActive = true;
      }
    });
    dispatch(changeSubStepsAction(subSteps));
    dispatch(changeStepAction(2));
    dispatch(PasswordFailureAction(''));
    dispatch(AuthZFailureAction(false));
    dispatch(DataModelAction(false));
  };

  const hasMultipleContracts = (res: any) =>
    Object.keys(res.account).length === 0;
  const handleSingleAgreement = (res: any) => {
    handlePassDetails(res.account, false);
    switch (true) {
      case res.account.isRegistered:
        setIsRegistered(res.account.isRegistered);
        dispatch(DataModelAction(true));
        break;
      case isEmptyString(res.account.obscuredPhoneNumber):
        setIsContactFound(false);
        setIsRegistered(false);
        dispatch(DataModelAction(true));
        break;
      case res.account.isWSS:
        subSteps.splice(1, 1, {
          description: FormSubSteps.CHANGE_EMAIL_ADDRESS,
          isActive: false,
        });
        handleStepper(false);
        break;
      case isNoEmailFound(res.account.emailAddress):
        setIsEmailFound(false);
        setPhoneNumber(res.account.obscuredPhoneNumber);
        setIsContactFound(true);
        break;
      case subSteps.findIndex(
        (item) => item.description === FormSubSteps.MULTI_AGREEMENT,
      ) !== -1:
        subSteps.splice(1, 1);
        break;
      default:
        handleStepper(false);
        break;
    }
  };

  const handleApiResponse = async (res: {
    hasAllRegistrationNumbers: boolean;
  }) => {
    if (hasMultipleContracts(res)) {
      initialSubSteps.splice(1, 0, {
        description: FormSubSteps.MULTI_AGREEMENT,
        isActive: false,
      });
      dispatch(changeSubStepsAction(initialSubSteps));
      handleStepper(false, true);
      dispatch(HasRegistrationNumberAction(res.hasAllRegistrationNumbers));
      return;
    }
    handleSingleAgreement(res);
  };
  const handleRequestBody = () => {
    if (hasAllRegistrationNumbers)
      return { vehicleRegistrationNumber: agreementNo, contractId: '' };
    return { vehicleRegistrationNumber: '', contractId: agreementNo };
  };
  const handleConfirmAccountApiResponse = (res: any) => {
    if (res.isWSS) {
      resetSubSteps();
      subSteps.splice(3, 0, {
        description: FormSubSteps.CHANGE_EMAIL_ADDRESS,
        isActive: true,
      });
      dispatch(changeStepAction(2));
      dispatch(changeSubStepsAction(subSteps));
      handlePassDetails(res, true);
    } else {
      handlePassDetails(res, true);
      handleStepper(false);
    }
  };

  const sendOTP = () => {
    authSMSSender(mtaCustomerId, wssCustomerID)
      .then((res: { authSessionId: string; otpExpiresAt: string }) => {
        dispatch(ShowHideSpinnerAction(false));
        setIsApiCalled(false);
        setJourneySessionId(res.authSessionId);
        setExpiryTime(res.otpExpiresAt);
      })
      .catch(() => {
        dispatch(ShowHideSpinnerAction(false));
        setIsApiCalled(false);
      });
  };

  const handleOnClickContinue = (): void => {
    setIsContinueClicked(true);
    setIsDateField(true);
    handleIsRegistered(false);
    dispatch(UpdateDOBPostcodeAction(postCode, date, false));
    switch (true) {
      case currentSubStep === FormSubSteps.PERSONAL_DETAILS && isDateError:
        return;
      case currentSubStep === FormSubSteps.PERSONAL_DETAILS && isPostCodeError:
        return;
      case currentSubStep === FormSubSteps.PERSONAL_DETAILS &&
        !isDateError &&
        !isPostCodeError:
        dispatch(ShowHideSpinnerAction(true));
        setIsApiCalled(true);
        dispatch(CustomerAgreementNumberAction(''));
        dispatch(
          DOBPostcodeContractsDataAction([], {
            isDetailsFound: true,
            isApiFailed: false,
            isMoreThanOneUser: false,
          }),
        );
        registrationAPI(
          dateFormat(date, 'yyyy-mm-dd'),
          handleOnPostcodeFormat(postCode),
        )
          .then((res: any) => {
            setIsContinueClicked(false);
            isApiFailed.current = false;
            handleApiResponse(res);
            dispatch(ShowHideSpinnerAction(false));
            setIsApiCalled(false);
            setIsCustomerRecordFound(true);
            setIsAgreementFound(true);
          })
          .catch((err: { message: string }) => {
            dispatch(ShowHideSpinnerAction(false));
            setIsApiCalled(false);
            if (err.message === apiErrorMessage) {
              setIsCustomerRecordFound(false);
              isApiFailed.current = false;
              setIsAgreementFound(false);
              handleOnModalOpen();
            } else {
              isApiFailed.current = true;
              handleOnModalOpen();
            }
          });

        break;
      case currentSubStep === FormSubSteps.MULTI_AGREEMENT:
        setIsApiCalled(true);
        dispatch(ShowHideSpinnerAction(true));
        dispatch(
          DOBPostcodeContractsDataAction([], {
            isDetailsFound: true,
            isApiFailed: false,
            isMoreThanOneUser: false,
          }),
        );
        dispatch(CustomerAgreementNumberAction(agreementNo));
        confirmAccountAPI(
          handleRequestBody(),
          dateFormat(date, 'yyyy-mm-dd'),
          handleOnPostcodeFormat(postCode),
        )
          .then((res: any) => {
            dispatch(ShowHideSpinnerAction(false));
            setIsApiCalled(false);
            handleConfirmAccountApiResponse(res.data.Data);
            setIsCustomerRecordFound(true);
            setIsAgreementFound(true);
            setIsContinueClicked(false);
          })
          .catch(() => {
            setIsCustomerRecordFound(true);
            setIsAgreementFound(true);
            setIsCustomerRecordFound(false);
            setIsAgreementFound(false);
            handleOnModalOpen();
            setIsApiCalled(false);
            dispatch(ShowHideSpinnerAction(false));
          });
        break;
      case currentSubStep === FormSubSteps.SEND_OTP:
        dispatch(ShowHideSpinnerAction(true));
        setIsApiCalled(true);
        sendOTP();
        handleStepper(false);
        break;
      case currentSubStep === FormSubSteps.CHANGE_EMAIL_ADDRESS:
        handleStepper(false, false, true);
        break;
      default:
        handleStepper(false);
        break;
    }
  };

  const handleOnButtonRenderCondition = (): boolean =>
    stepNumber === 1 ||
    (currentSubStep !== FormSubSteps.CHANGE_EMAIL_ADDRESS && stepNumber !== 3);

  const renderButtonGroup = (): ReactElement => (
    <ContentGroup marginRight="03" marginLeft="03" marginBottom="none">
      <Grid alignX="right">
        {getSubStepName() === FormSubSteps.CONFIRM_EMAIL_ADDRESS && (
          <StyledMarginButton
            variation="secondary"
            data-qa-id={dataQaIds.loginAndRegistration.changeEmailBtn}
            onClick={() => handleOnChangeEmailStep(true)}
          >
            {changeEmailLabel}
          </StyledMarginButton>
        )}
        {handleOnButtonRenderCondition() && (
          <Button
            data-qa-id={dataQaIds.loginAndRegistration.confirmAndContinueBtn}
            data-testid="continueBtn"
            onClick={handleOnClickContinue}
          >
            {renderContinueButtonLabel()}
          </Button>
        )}
      </Grid>
    </ContentGroup>
  );

  const renderStepContent = (): ReactElement => {
    switch (true) {
      case stepNumber === 1:
        return (
          <RegistrationYourDetails
            isContinueClicked={isContinueClicked}
            setIsContinueClicked={setIsContinueClicked}
            handleOnPostCodeError={handleOnPostCodeError}
            handleOnDateError={handleOnDateError}
            handleOnDate={handleOnDate}
            handleOnPostCode={handleOnPostCode}
            isDateField={isDateField}
            setIsDateField={setIsDateField}
            handleAgreementNumber={setAgreementNo}
          />
        );
      case stepNumber === 2 &&
        currentSubStep === FormSubSteps.CONFIRM_EMAIL_ADDRESS:
        return <RegistrationCredentials email={email} />;
      case stepNumber === 2 &&
        currentSubStep === FormSubSteps.CHANGE_EMAIL_ADDRESS:
        return (
          <ChangeEmail
            handleStepper={handleOnClickContinue}
            handleOnReverseStepper={() => handleStepper(true)}
            mtaCustomerId={mtaCustomerId}
            handleChangeEmailToggle={handleOnChangeEmailStep}
            isEmailFound={isEmailFound}
          />
        );

      case stepNumber === 2 && currentSubStep === FormSubSteps.SEND_OTP:
        return (
          <RegistrationAuthenticate
            phoneNumber={phoneNumber}
            isNumberFound={isContactFound}
          />
        );
      case stepNumber === 3 && currentSubStep === FormSubSteps.VERIFY_OTP:
        return (
          <OTPAuthentication
            isForgotEmail={false}
            sessionId={journeySessionId}
            phoneNumber={phoneNumber}
            handleSendOtp={sendOTP}
            mtaCustomerId={mtaCustomerId}
            isForgetPassword={false}
            brand={brand}
            handleSessionId={handleOnSessionId}
            expiryTime={expiryTime}
            isLogin={false}
            handleStepper={() => handleStepper(false)}
          />
        );
      default:
        return (
          <SetupPassword
            handleStepper={handleOnClickContinue}
            isForgotPassword={false}
            handleOnPassword={handleOnPwd}
            mtaCustomerId={mtaCustomerId}
          />
        );
    }
  };

  const renderHr = (text: string): ReactElement => (
    <>
      <GridItem>
        <Hr marginTop="05" marginBottom="05" />
      </GridItem>
      <GridItem>
        <Text size="s2">{text}</Text>
      </GridItem>
    </>
  );
  const renderLink = (): ReactElement => {
    if (
      currentSubStep === FormSubSteps.CONFIRM_EMAIL_ADDRESS ||
      currentSubStep === FormSubSteps.CHANGE_EMAIL_ADDRESS
    ) {
      return (
        <LinkButton
          handleButtonClick={handleNavigate}
          testId="howWeEmailUseLink"
          dataQaId={dataQaIds.loginAndRegistration.howWeEmailUseLink}
        >
          {howUseEmailLabel}
        </LinkButton>
      );
    }
    if (currentSubStep === FormSubSteps.SEND_OTP) {
      return <PhoneLink dataQaId="some-id" brand={brand} />;
    }
    return null;
  };

  const renderBottomContent = (): ReactElement => {
    if (currentSubStep === FormSubSteps.CONFIRM_EMAIL_ADDRESS) {
      return (
        <>
          {renderHr(yourDataLabel)}
          <GridItem>
            <StyledInlineBox>{renderLink()}</StyledInlineBox>
          </GridItem>
        </>
      );
    }
    if (currentSubStep === FormSubSteps.SEND_OTP) {
      return (
        <>
          {isContactFound && renderHr(numberNotRightLabel)}
          <GridItem>
            <ContentGroup
              marginTop={isContactFound ? '04' : 'none'}
              marginBottom="none"
            >
              <StyledInlineBox>{renderLink()}</StyledInlineBox>
            </ContentGroup>
          </GridItem>
        </>
      );
    }
    return null;
  };
  const handleOnRender = (): ReactElement =>
    isApiCalled && utilsStore.showSpinner ? (
      <SpinnerContainer brand={brand} />
    ) : (
      <>
        <Grid>
          <GridItem lg={2} />
          <GridItem lg={8}>
            <NavigatePreviousLink
              headingText={createYourAccountLabel}
              navigateRoute={() => handleStepper(true)}
            />
            <Heading size="s6" marginBottom="03" aria-level={2}>
              {renderStepTitle()}
            </Heading>
            <FormStepper
              activeStep={stepNumber}
              numberOfSteps={numberOfSteps}
            />
            <StyledBox padding={stepNumber !== 1}>
              <Grid>
                {renderStepContent()}
                <GridItem>{renderButtonGroup()}</GridItem>
                {renderBottomContent()}
              </Grid>
            </StyledBox>
            <NeedHelp
              dataQaId={dataQaIds.needhelp.regContainer}
              brand={brand}
            />
          </GridItem>
          <GridItem lg={2} />
        </Grid>
        <RegistrationModal
          brand={brand}
          isAgreementFound={isAgreementFound}
          isContactFound={isContactFound}
          isCustomerRecordFound={isCustomerRecordFound}
          handleCloseWarningDialog={handleCloseWarningDialog}
          handleOnNavigateHomepage={handleOnNavigateHomepage}
          handleBackToResendPasscode={handleBackToResendPasscode}
          isRegistered={isRegistered}
          isApiFailed={isApiFailed.current}
          isLogin={false}
        />

        {hasPasswordSet && (
          <DataModal
            brand={brand}
            onClick={() => handleNavigateLogin()}
            closeIcon={false}
            icon={<SuccessIcon colour={theme.color_text_default_1} />}
            description={dataModalText}
            heading={dataModalHeading}
            buttonTxt={continueLabel}
            dataQaId={{
              btn: dataQaIds.loginAndRegistration.regcompleteBtn,
            }}
            popupName="Online account successfully created"
          />
        )}
      </>
    );

  return handleOnRender();
}
