import React, {
  ChangeEvent,
  ReactElement,
  useEffect,
  useRef,
  useState,
} from 'react';

import {
  Button,
  ContentGroup,
  Grid,
  GridItem,
  Text,
} from '@constellation/core';
import { useContent } from '@interstellar/react-app-content';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from 'styled-components';

import { SetPasswordProps, SetupPasswordContent } from './setPassword.config';
import { authUpdateEmailAPI } from '../../apis/authUpdateEmailAPI';
import { authZAPI } from '../../apis/authZApi';
import { authZTokenAPI } from '../../apis/authZTokenApi';
import { createPassword } from '../../apis/createPasswordApi';
import { getCustomerAPI } from '../../apis/getCustomerAPI';
import CircleIcon from '../../assets/icons/circleIcon';
import TickIcon from '../../assets/icons/tickIcon';
import {
  PASSWORD_ADD_PATTERN,
  passwordCharacters,
  passwordPattern,
  REGEX,
} from '../../constants/REGEX';
import dataQaIds from '../../dataModel/dataQaIds';
import { RootState } from '../../store';
import { DataModelAction } from '../../store/action/dataModal.action';
import { forgotEmailAction } from '../../store/action/login.action';
import {
  AuthTokenAction,
  AuthZFailureAction,
  OldPasswordFailureAction,
  PasswordFailureAction,
  PasswordSetAction,
} from '../../store/action/registration.action';
import { UpdateEmailAPIAction } from '../../store/action/updateEmail.action';
import { ShowHideSpinnerAction } from '../../store/action/utilsModal.action';
import { challenge, verifier } from '../../utils/codeGenerator';
import { setCookie } from '../../utils/cookie';
import { dateFormat } from '../../utils/dateFormat';
import { isNftInt, isPre } from '../../utils/handleEnvVariables';
import { handleOnPostcodeFormat } from '../../utils/handleOnPostcodeFormat';
import { hasRepeatedCharacters } from '../../utils/hasRepeatedCharacters';
import { isEmptyString } from '../../utils/isEmptyString';
import {
  StyledBtnBox,
  StyledConstrainBox,
  StyleIconBox,
} from '../appConfig/common.styled';
import PasswordField from '../passwordField/passwordField';

export default function SetPassword({
  isForgotPassword,
  isRegistration,
  isLogin,
  handleOnPasswordValue,
  oldPasswordErrorMessage,
  mtaCustomerId,
  handleOnClick,
  authliteCommmonPasswordErrorMsg,
}: SetPasswordProps): ReactElement {
  const {
    passwordConstrain,
    emptyFieldErrorMessage,
    confirmPasswordFieldLabel,
    createAccountButtonLabel,
    constrainErrorMessage,
    continueLabel,
    specialCharErrorMessage,
    passwordNotMatchErrorMessage,
    setPasswordFieldLabel,
    createPasswordFieldLabel,
    createPasswordConstrain,
    repeatedCharactersErrorMessage,
    authliteApiErrorMessage,
    authlitePasswordErrorMessage,
    invalidWordApiErrorMessage,
  } = useContent<SetupPasswordContent>();
  const theme: any = useTheme();
  const { isUpdateEmail, customerData, dob, postcode, authToken } = useSelector(
    (state: RootState) => state.RegistrationReducer,
  );
  const { emailAddress } = useSelector(
    (state: RootState) => state.LoginReducer,
  );

  const dispatch = useDispatch();
  const [password, setPassword] = useState('');
  const confirmPasswordRef = useRef(null);
  const [createFieldErrorMsg, setCreateFieldErrorMsg] = useState('');
  const [confirmFieldErrorMsg, setConfirmFieldErrorMsg] = useState('');
  const [showBox, setShowBox] = useState(!isForgotPassword);
  const [dataArray, setDataArray] = useState(
    passwordConstrain.map((items) => ({
      ...items,
      icon: <CircleIcon colour={theme.color_icon_default_2} />,
    })),
  );
  const dataPattern = new RegExp(PASSWORD_ADD_PATTERN, 'i');
  const passwordValidation = (entries: string): object => {
    const result = {};
    Object.entries(passwordPattern).forEach(([constrain, pattern]) => {
      result[constrain] = new RegExp(pattern).test(entries);
    });
    return result;
  };
  useEffect(() => {
    if (oldPasswordErrorMessage !== '') {
      setCreateFieldErrorMsg(oldPasswordErrorMessage);
    }
  }, [oldPasswordErrorMessage]);
  useEffect(() => {
    if (authliteCommmonPasswordErrorMsg !== '') {
      setCreateFieldErrorMsg(authliteCommmonPasswordErrorMsg);
    }
  }, [authliteCommmonPasswordErrorMsg]);
  const handleFieldName = (txtFieldName: string): boolean =>
    txtFieldName === 'createPassword';
  const handleOnPCSetErrMsg = (value: string, name: string): void => {
    if (handleFieldName(name)) {
      setCreateFieldErrorMsg(value);
    } else setConfirmFieldErrorMsg(value);
  };

  const handlePasswordChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const { value, name } = event.target;
    if (handleFieldName(name)) {
      setDataArray((item) =>
        item.map((icon) => ({
          ...icon,
          icon: passwordValidation(value)[icon.validEntry] ? (
            <TickIcon colour={theme.color_icon_success} />
          ) : (
            <CircleIcon colour={theme.color_icon_default_2} />
          ),
        })),
      );
      setShowBox(true);
      setPassword(value);
      handleOnPasswordValue(value);
    }
    handleOnPCSetErrMsg('', name);
  };
  const handleOnCreateFieldErrMsg = (value: string, name: string): void => {
    const isPatternMatched = Object.values(passwordValidation(value)).some(
      (isValidPassword) => isValidPassword === false,
    );
    switch (true) {
      case isEmptyString(value):
        handleOnPCSetErrMsg(emptyFieldErrorMessage, name);
        break;
      case dataPattern.test(value):
        handleOnPCSetErrMsg(constrainErrorMessage, name);
        break;
      case hasRepeatedCharacters(value):
        handleOnPCSetErrMsg(repeatedCharactersErrorMessage, name);
        break;
      case isPatternMatched || !new RegExp(passwordCharacters).test(value):
        handleOnPCSetErrMsg(
          new RegExp(passwordCharacters).test(value)
            ? constrainErrorMessage
            : specialCharErrorMessage,
          name,
        );
        break;
      default:
        isEmptyString(createFieldErrorMsg);
        setShowBox(false);
        break;
    }
  };
  const handleOnConfirmFieldErrMsg = (value: string, name: string): void => {
    if (isEmptyString(value)) {
      handleOnPCSetErrMsg(emptyFieldErrorMessage, name);
    } else if (value !== password) {
      handleOnPCSetErrMsg(passwordNotMatchErrorMessage, name);
    }
  };
  const handleBlurEvt = (
    event: React.FocusEvent<HTMLInputElement, Element>,
  ): void => {
    const { value, name } = event.target;
    if (handleFieldName(name)) {
      handleOnCreateFieldErrMsg(value, name);
    } else {
      handleOnConfirmFieldErrMsg(value, name);
    }
  };

  const hasValidInputs = (): boolean => {
    if (isEmptyString(password)) {
      setCreateFieldErrorMsg(emptyFieldErrorMessage);
      return false;
    }
    if (isEmptyString(confirmPasswordRef.current.value)) {
      setConfirmFieldErrorMsg(emptyFieldErrorMessage);
      return false;
    }
    if (confirmPasswordRef.current.value !== password) {
      setConfirmFieldErrorMsg(passwordNotMatchErrorMessage);
      return false;
    }
    return (
      isEmptyString(confirmFieldErrorMsg) && isEmptyString(createFieldErrorMsg)
    );
  };
  const handlePassedDoB = (passedDob: string) => {
    return {
      day: passedDob.split('-')[2],
      month: passedDob.split('-')[1],
      year: passedDob.split('-')[0],
    };
  };

  const handleWssRequestBody = (
    passedPostcode = '',
    passedDob = '',
  ): object => {
    const dateOfBirth = !isEmptyString(passedDob)
      ? dateFormat(handlePassedDoB(passedDob), 'dd/mm/yyyy')
      : dateFormat(dob, 'dd/mm/yyyy');
    const postCode = passedPostcode || postcode;
    if (customerData[0].isWSS)
      return {
        dateOfBirth,
        postCode: handleOnPostcodeFormat(postCode),
        isAutoRegister: false,
        isWSS: customerData[0].isWSS,
      };
    return {
      dateOfBirth,
      postCode: handleOnPostcodeFormat(postCode),
      isAutoRegister: false,
      isWSS: false,
    };
  };
  const getJourneyName = () => {
    if (isForgotPassword) {
      return 'forgotPassword';
    }
    if (isRegistration) {
      return 'registration';
    }
    return 'login';
  };
  const handleApiErrorMessage = (error: any) => {
    if (
      error.response.status === 422 &&
      (error?.response?.data?.StatusMsg === authliteApiErrorMessage ||
        error?.response?.data?.[0]?.errors === invalidWordApiErrorMessage)
    ) {
      setCreateFieldErrorMsg(authlitePasswordErrorMessage);
      dispatch(ShowHideSpinnerAction(false));
      dispatch(PasswordFailureAction(''));
      dispatch(DataModelAction(false));
    } else {
      setCreateFieldErrorMsg('');
      dispatch(ShowHideSpinnerAction(false));
      dispatch(PasswordFailureAction(getJourneyName()));
      dispatch(DataModelAction(true));
    }
  };
  const handleOnCreatePassword = (
    contractId: string,
    emailID: string,
    bpid: string,
    accessToken: string,
    additionalData: object,
  ) => {
    createPassword(
      mtaCustomerId || customerData[0].wssCustomerID,
      password,
      parseInt(contractId, 10),
      emailID,
      parseInt(bpid, 10),
      { Authorization: `Bearer ${accessToken}` },
      additionalData,
    )
      .then(() => {
        dispatch(forgotEmailAction(emailID));
        dispatch(PasswordSetAction(true));
        dispatch(DataModelAction(true));
        dispatch(ShowHideSpinnerAction(false));
      })
      .catch((error) => {
        handleApiErrorMessage(error);
      });
  };
  const getMTABPID = (res): string => {
    const onlyNumbers = new RegExp(REGEX.ONLY_NUMBERS);
    return res.find((data) => onlyNumbers.test(data.businessPartnerId))
      .businessPartnerId;
  };
  const getEmail = (accessToken: string) => {
    dispatch(ShowHideSpinnerAction(true));
    getCustomerAPI(mtaCustomerId, { Authorization: `Bearer ${accessToken}` })
      .then(
        async (
          res: {
            emailAddress: string;
            contractId: string;
            businessPartnerId: string;
            postcode: string;
            dob: string;
          }[],
        ) => {
          handleOnCreatePassword(
            res[0].contractId,
            res[0].emailAddress,
            getMTABPID(res),
            accessToken,
            handleWssRequestBody(
              handleOnPostcodeFormat(res[0].postcode),
              res[0].dob,
            ),
          );
        },
      )
      .catch(() => {
        dispatch(ShowHideSpinnerAction(false));
      });
  };
  const updateEmail = (accessToken: string): void => {
    dispatch(ShowHideSpinnerAction(true));
    authUpdateEmailAPI(mtaCustomerId, emailAddress, {
      Authorization: `Bearer ${accessToken}`,
    })
      .then(() => {
        dispatch(UpdateEmailAPIAction(false));
        getEmail(accessToken);
      })
      .catch(() => {
        dispatch(ShowHideSpinnerAction(false));
      });
  };
  const handleAuthResponse = (response) => {
    if (isForgotPassword) {
      return;
    }
    if (!isEmptyString(response) && !isUpdateEmail) {
      getEmail(response);
    }
    if (
      !isEmptyString(response) &&
      !isForgotPassword &&
      isUpdateEmail &&
      !customerData[0].isWSS
    ) {
      updateEmail(response);
    }
    if (
      !isEmptyString(response) &&
      customerData[0].isWSS &&
      !isForgotPassword
    ) {
      handleOnCreatePassword(
        customerData[0].contractId,
        emailAddress,
        '1',
        response,
        handleWssRequestBody(),
      );
    }
  };

  const handleAuthorization = () => {
    dispatch(AuthZFailureAction(false));
    dispatch(DataModelAction(false));
    dispatch(OldPasswordFailureAction(false));
    const dateExpiry = new Date().getTime() + 30 * 60000;
    if (!isNftInt) {
      authZAPI(authToken, challenge)
        .then((res: any) => {
          if (isPre) {
            handleAuthResponse(res);
            dispatch(AuthTokenAction(res));
            handleOnClick(res);
            return;
          }
          authZTokenAPI(res.authorization_code, verifier)
            .then((auth: { access_token: string; refresh_token: string }) => {
              dispatch(AuthTokenAction(auth.access_token));
              handleOnClick(auth.access_token);
              setCookie('refreshToken', auth.refresh_token, dateExpiry);
              setCookie('authCode', res.authorization_code, dateExpiry);
              setCookie('verifier', verifier, dateExpiry);
              handleAuthResponse(auth.access_token);
            })
            .catch(() => {
              dispatch(DataModelAction(true));
              dispatch(ShowHideSpinnerAction(false));
              dispatch(AuthZFailureAction(true));
            });
        })
        .catch(() => {
          dispatch(ShowHideSpinnerAction(false));
          dispatch(AuthZFailureAction(true));
          dispatch(DataModelAction(true));
        });
    } else {
      handleAuthResponse('123');
    }
  };
  const handleOnCreateAccount = (): void => {
    if (hasValidInputs()) {
      handleAuthorization();
    }
  };
  const renderButtonGroup = (): ReactElement => (
    <StyledBtnBox>
      <Button
        data-testid="createAccbtn"
        onClick={handleOnCreateAccount}
        data-qa-id={dataQaIds.loginAndRegistration.createAccBtn}
      >
        {isForgotPassword || isLogin ? continueLabel : createAccountButtonLabel}
      </Button>
    </StyledBtnBox>
  );

  return (
    <>
      <Grid>
        <GridItem lg={8} md={8}>
          <PasswordField
            label={
              !isRegistration ? setPasswordFieldLabel : createPasswordFieldLabel
            }
            name="createPassword"
            testId="createPassword"
            onChange={handlePasswordChange}
            onBlur={handleBlurEvt}
            error={createFieldErrorMsg}
            inputWidth="fluid"
            fieldMarginTop={isForgotPassword || isLogin ? 'none' : '05'}
            dataQaId={dataQaIds.loginAndRegistration.createPasswordField}
            fieldMarginBottom={showBox ? 'none' : '05'}
            data-cs-mask
            autocomplete="new-password"
          />
          {showBox && (
            <StyledConstrainBox
              bgColor="none"
              marginBottom="05"
              marginTop="03"
              data-testid="constrainBox"
            >
              <ContentGroup marginBottom="05">
                <Text weight="bold" size="s2">
                  {createPasswordConstrain}
                </Text>
              </ContentGroup>
              {dataArray.map((items, index) => (
                <StyleIconBox key={`constrain_${index + 1}`}>
                  {items.icon}
                  <Text size="s2" marginLeft="04">
                    {dataArray[index].constrain}
                  </Text>
                </StyleIconBox>
              ))}
            </StyledConstrainBox>
          )}
          <PasswordField
            label={confirmPasswordFieldLabel}
            name="confirmPassword"
            testId="confirmPassword"
            onBlur={handleBlurEvt}
            onChange={handlePasswordChange}
            inputRef={confirmPasswordRef}
            error={confirmFieldErrorMsg}
            inputWidth="fluid"
            dataQaId={dataQaIds.loginAndRegistration.confirmPasswordField}
            fieldMarginBottom="07"
            data-cs-mask
            autocomplete="new-password"
          />
        </GridItem>
      </Grid>
      {renderButtonGroup()}
    </>
  );
}
