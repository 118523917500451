import { DateProps } from '../../components/appConfig/AppConfig';
import { ApiAuthResponse } from '../../routes/registration/Registration.config';

export interface UpdateDOBPostcodeInterface {
  type: string;
  postcode: string;
  dob: DateProps;
  resetValues?: boolean;
}

export const UpdateDOBPostcodeAction = (
  postcode: string,
  dob: DateProps,
  resetValues?: boolean,
): UpdateDOBPostcodeInterface => ({
  type: 'UPDATE_POSTCODE_DOB',
  postcode,
  dob,
  resetValues,
});

export interface DOBPostcodeContractsInterface {
  type: string;
  customerData: ApiAuthResponse[];
  constraints: {
    isDetailsFound?: boolean;
    isApiFailed?: boolean;
    isMoreThanOneUser?: boolean;
  };
}

export const DOBPostcodeContractsDataAction = (
  customerData: ApiAuthResponse[],
  constraints?: {
    isDetailsFound?: boolean;
    isApiFailed?: boolean;
    isMoreThanOneUser?: boolean;
  },
): DOBPostcodeContractsInterface => ({
  type: 'POSTCODE_DOB_DATA',
  customerData,
  constraints,
});

export interface CustomerMtaDataInterface {
  type: string;
  customerData: ApiAuthResponse[];
}

export const CustomerDataAction = (
  customerData: ApiAuthResponse[],
): CustomerMtaDataInterface => ({
  type: 'CUSTOMER_DATA',
  customerData,
});

export interface CustomerWssDataInterface {
  type: string;
  customerDataWss: ApiAuthResponse[];
  contracts: {
    isMTA?: boolean;
    isWSS?: boolean;
  };
  userToken: string;
  wssCustomerContactInfo: string;
}

export const CustomerWssDataAction = (
  customerDataWss: ApiAuthResponse[],
  contracts: {
    isMTA?: boolean;
    isWSS?: boolean;
  },
  userToken: string,
  wssCustomerContactInfo: string,
): CustomerWssDataInterface => ({
  type: 'CUSTOMER_DATA_WSS',
  customerDataWss,
  contracts,
  userToken,
  wssCustomerContactInfo,
});

export interface CustomerAgreementNumberInterface {
  type: string;
  agreementNumber: string;
}

export const CustomerAgreementNumberAction = (
  agreementNumber: string,
): CustomerAgreementNumberInterface => ({
  type: 'CUSTOMER_AGREEMENT_NUMBER',
  agreementNumber,
});

export interface HasRegistrationNumberInterface {
  type: string;
  hasAllRegistrationNumbers: boolean;
}

export const HasRegistrationNumberAction = (
  hasAllRegistrationNumbers: boolean,
): HasRegistrationNumberInterface => ({
  type: 'HAS_REGISTRATION_NUMBERS',
  hasAllRegistrationNumbers,
});

export interface ContactNumberFoundInterface {
  type: string;
  isContactNumberFound: boolean;
}

export interface ApiCalledInterface {
  type: string;
  isApiCalled: boolean;
}

export const ContactNumberFoundAction = (
  isContactNumberFound: boolean,
): ContactNumberFoundInterface => ({
  type: 'CONTACT_NUMBER_FOUND',
  isContactNumberFound,
});

export const ApiCalledAction = (isApiCalled: boolean): ApiCalledInterface => ({
  type: 'API_CALLED',
  isApiCalled,
});

export interface AuthTokenInterface {
  type: string;
  authToken: string;
}

export const AuthTokenAction = (authToken: string): AuthTokenInterface => ({
  type: 'AUTH_TOKEN',
  authToken,
});

export interface PasswordSetInterface {
  type: string;
  hasPasswordSet: boolean;
}

export const PasswordSetAction = (
  hasPasswordSet: boolean,
): PasswordSetInterface => ({
  type: 'PASSWORD_SET',
  hasPasswordSet,
});

export interface PasswordFailureInterface {
  type: string;
  passwordFailureOrigin: string;
}

export const PasswordFailureAction = (
  passwordFailureOrigin: string,
): PasswordFailureInterface => ({
  type: 'PASSWORD_FAILURE',
  passwordFailureOrigin,
});

export interface AuthZFailureInterface {
  type: string;
  isAuthZFailed: boolean;
}
export const AuthZFailureAction = (
  isAuthZFailed: boolean,
): AuthZFailureInterface => ({
  type: 'AUTHZ_FAILURE',
  isAuthZFailed,
});

export interface OldPasswordFailureInterface {
  type: string;
  isOldPassword: boolean;
}
export const OldPasswordFailureAction = (
  isOldPassword: boolean,
): OldPasswordFailureInterface => ({
  type: 'OLD_PASSWORD_FAILURE',
  isOldPassword,
});
