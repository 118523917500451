import React, { ReactElement, useEffect, useRef, useState } from 'react';

import {
  BackgroundProvider,
  Button,
  Container,
  ContentGroup,
  Grid,
  GridItem,
  Heading,
  pxToRem,
  Spinner,
  useWindowSize,
} from '@constellation/core';
import ButtonProps from '@constellation/core/dist/types/components/Button/button.types';
import { useContent } from '@interstellar/react-app-content';
import { useNavigate } from '@interstellar/react-app-routing';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from 'styled-components';

import LoginCredentials from './loginCredentials';
import {
  StyledBackgroundImage,
  StyledBoxContainer,
  StyledContentBox,
  StyledLoginBtnGridItem,
  StyledWelcomeHeader,
} from './Welcome.styled';
import { WelcomeContent } from './WelcomePage.config';
import { authLoginApi } from '../../apis/authLoginApi';
import { authZAPI } from '../../apis/authZApi';
import { authZMockApi } from '../../apis/authZMockApi';
import { authZTokenAPI } from '../../apis/authZTokenApi';
import { MarginNumbers } from '../../components/appConfig/AppConfig';
import LinkButton from '../../components/linkButton/LinkButton';
import { REGEX } from '../../constants/REGEX';
import { useDeviceResizing } from '../../customHooks/useDeviceResizing';
import dataQaIds from '../../dataModel/dataQaIds';
import { RootState } from '../../store';
import { DataModelAction } from '../../store/action/dataModal.action';
import { changeStepAction } from '../../store/action/formStep.action';
import {
  forgotEmailAction,
  LoginCredentialsAction,
  loginModalAction,
  OldPasswordValueSet,
} from '../../store/action/login.action';
import {
  ApiCalledAction,
  AuthZFailureAction,
  DOBPostcodeContractsDataAction,
  OldPasswordFailureAction,
  UpdateDOBPostcodeAction,
} from '../../store/action/registration.action';
import { UpdateEmailAPIAction } from '../../store/action/updateEmail.action';
import { challenge, verifier } from '../../utils/codeGenerator';
import { dataLayer } from '../../utils/dataLayer';
import { isInt, isNftInt, isPre } from '../../utils/handleEnvVariables';
import { isEmptyString } from '../../utils/isEmptyString';
import { redirectURL } from '../../utils/redirectURL';
import * as routes from '../manifest';

export default function WelcomePage(): ReactElement {
  const theme: any = useTheme();
  const dispatch = useDispatch();
  const emailPattern = new RegExp(REGEX.EMAIL_PATTERN);
  const { width: windowWidth } = useWindowSize();
  const isNarrow = pxToRem(windowWidth) < parseFloat(theme.breakpoint_lg);
  const isWide = !isNarrow;
  const Logo = isWide ? theme.assets.logo.wide : theme.assets.logo.base;
  const userName = useRef('');
  const password = useRef('');
  const [isLoginCredentialError, setIsLoginCredentialError] = useState(false);
  const [emailErrMsg, setEmailErrMsg] = useState('');
  const [passwordErrMsg, setPasswordErrMsg] = useState('');
  const [isWssDown, setIsWssDown] = useState(false);
  const [isLoginDisabled, setIsLoginDisabled] = useState(false);
  const {
    welcomeMessage,
    registrationLabel,
    createAccountLabel,
    needHelpText,
    goBackLabel,
    emailErrorMessage,
    emptyFieldErrorMessage,
    loginNotRegisteredApiErrorMessage,
    loginAccountNotActiveErrorMessage,
    loginWssUpdatePasswordErrorMessage,
  } = useContent<WelcomeContent>();
  const navigate = useNavigate();
  const isDeviceFound = useDeviceResizing();
  const { isLoginCredentials } = useSelector(
    (state: RootState) => state.LoginReducer,
  );

  useEffect(() => {
    window.sessionStorage.removeItem('email');
    window.sessionStorage.removeItem('auth_token');
    window.sessionStorage.removeItem('contractNumber');
    window.sessionStorage.removeItem('multiContract');
    window.sessionStorage.removeItem('persist:root');
    window.appConfig.JOURNEY_NAME = 'Welcome page';
    window.appConfig.JOURNEY_STEP_NAME = isLoginCredentials
      ? 'Login to your account'
      : 'Finance to suit you';
    window.appConfig.PAGE_ROLE = 'Landing Page';
    dataLayer();
  }, [isLoginCredentials]);

  useEffect(() => {
    dispatch(
      UpdateDOBPostcodeAction('', { day: '', month: '', year: '' }, true),
    );
    dispatch(
      DOBPostcodeContractsDataAction([], {
        isDetailsFound: true,
        isApiFailed: false,
        isMoreThanOneUser: false,
      }),
    );
    dispatch(OldPasswordValueSet(''));
    dispatch(DataModelAction(false));
    dispatch(UpdateEmailAPIAction(false));
    dispatch(forgotEmailAction(''));
    dispatch(ApiCalledAction(false));
    dispatch(AuthZFailureAction(false));
    dispatch(OldPasswordFailureAction(false));
    dispatch(changeStepAction(1));
  }, [dispatch]);
  const handleMarginBottom = (): MarginNumbers => {
    if (isDeviceFound.tablet) return '07';
    if (isDeviceFound.mobile) return '04';
    return 'none';
  };

  const handleUserName = (email: string): void => {
    userName.current = email;
  };
  const handlePwd = (pwd: string): void => {
    password.current = pwd;
  };
  const handleWssCookie = (cookieData: string): string => {
    return cookieData.split(';')[0].split('=')[1];
  };

  const handleLoginCredentialError = (value: boolean): void => {
    setIsLoginCredentialError(value);
  };
  const handleQueryParam = (cookie: any): string => {
    if (isInt) return `&authorization=${handleWssCookie(cookie[0])}`;
    const data = handleWssCookie(cookie[0]);
    document.cookie = `Authorization=${data}`;
    return '';
  };

  const handleDeepLink = (separator: string) => {
    if (window.location.search.includes('redirectTo'))
      return `${separator}redirectTo=${
        window.location.search.split('?')[1].split('=')[1]
      }`;
    return '';
  };
  const handleRedirectPage = (
    redirectLink: string,
    wssCustomerID: string,
    contractId: string,
    accessToken: string,
    cookie?: any,
  ): void => {
    if (redirectLink.includes('multiple-agreement')) {
      if (!isEmptyString(cookie)) {
        document.cookie = `Authorization=${handleWssCookie(cookie[0])}`;
      }
      navigate(
        `${routes.MultipleAgreements}?${btoa(
          `auth_token=${accessToken}&a=1&email=${userName.current}`,
        )}${handleDeepLink('?')}`,
      );
    } else if (!isEmptyString(wssCustomerID)) {
      window.location.replace(
        `${redirectLink}blackhorse/dashboard#/myaccount?customerId=${wssCustomerID}&accountNumber=${contractId}&isArchived=false&username=${
          userName.current
        }${handleQueryParam(cookie)}${handleDeepLink('&')}
        `,
      );
    } else {
      redirectURL(
        'account-home',
        redirectLink,
        `auth_token=${accessToken}&contractNumber=${contractId}&email=${userName.current}`,
      );
    }
  };

  const handleOnLoginBtn = (): void => {
    dispatch(LoginCredentialsAction(true));
  };
  const handleOnEmailSetErrMsg = (value: string): void => setEmailErrMsg(value);

  const handleOnPasswordSetErrMsg = (value: string): void =>
    setPasswordErrMsg(value);
  /* iswssdown to be retirieved from the new login api response,code to be changed later */
  const handleWssDown = (value: boolean): void => setIsWssDown(value);
  const handleOnFieldsError = (): boolean => {
    if (isEmptyString(userName.current.trim())) {
      setEmailErrMsg(emptyFieldErrorMessage);
    }
    if (isEmptyString(password.current)) {
      setPasswordErrMsg(emptyFieldErrorMessage);
    }
    if (
      !isEmptyString(userName.current.trim()) &&
      !emailPattern.test(userName.current.trim())
    ) {
      setEmailErrMsg(emailErrorMessage);
    } else if (isEmptyString(emailErrMsg) && isEmptyString(passwordErrMsg)) {
      return true;
    }
    return false;
  };

  const authorizeLoginAndRedirect = (response) => {
    if (!isNftInt) {
      authZAPI(response.accessToken, challenge)
        .then((res: { authorization_code: string }) => {
          authZTokenAPI(res.authorization_code, verifier)
            .then((auth: { access_token: string; refresh_token: string }) => {
              handleRedirectPage(
                response.redirectLink,
                response?.wssCustomerID,
                response.contractId,
                auth.access_token,
                response?.cookie,
              );
              setIsLoginDisabled(false);
            })
            .catch(() => {
              setIsLoginCredentialError(true);
              setIsLoginDisabled(false);
            });
        })
        .catch(() => {
          setIsLoginCredentialError(true);
          setIsLoginDisabled(false);
        });
    } else {
      handleRedirectPage(
        response.redirectLink,
        response.wssCustomerID,
        response.contractId,
        '123',
        response?.cookie,
      );
      setIsLoginDisabled(false);
    }
  };
  const handleEmailId = (): string => `email=${userName.current}`;
  const handleApiError = (errorMsg: string) => {
    setIsLoginCredentialError(false);
    if (errorMsg === loginNotRegisteredApiErrorMessage) {
      dispatch(DataModelAction(true));
      dispatch(
        loginModalAction({
          isRegistered: false,
          isLoginAttemptExceeded: false,
          isLoginAttemptPrevented: false,
        }),
      );
    } else if (errorMsg === loginAccountNotActiveErrorMessage) {
      dispatch(DataModelAction(true));
      dispatch(
        loginModalAction({
          isRegistered: true,
          isLoginAttemptExceeded: false,
          isLoginAttemptPrevented: true,
        }),
      );
    } else if (errorMsg === loginWssUpdatePasswordErrorMessage) {
      dispatch(OldPasswordValueSet(btoa(password.current)));
      navigate(`${routes.SetPassword}?${btoa(handleEmailId())}`);
    } else {
      dispatch(DataModelAction(false));
      dispatch(
        loginModalAction({
          isRegistered: true,
          isLoginAttemptExceeded: false,
          isLoginAttemptPrevented: false,
        }),
      );
      setIsLoginCredentialError(true);
    }
  };
  const handleOnLoginCredentials = (e: any): void => {
    const formData = new FormData();
    formData.append('username', userName.current);
    formData.append('password', btoa(password.current));
    e.preventDefault();
    if (
      handleOnFieldsError() &&
      !isEmptyString(userName.current.trim()) &&
      !isEmptyString(password.current.trim())
    ) {
      setIsLoginDisabled(true);
      authLoginApi(formData)
        .then(
          (res: {
            Data: {
              redirectLink: string;
              accessToken: string;
              authSessionId: string;
              contractId: string;
              wssCustomerID: string;
              cookie?: any;
            };
          }) => {
            setIsLoginCredentialError(false);
            dispatch(
              loginModalAction({
                isRegistered: true,
                isLoginAttemptExceeded: false,
                isLoginAttemptPrevented: false,
              }),
            );
            if (isPre) {
              const token = JSON.parse(
                atob(res.Data.accessToken.split('.')[1]),
              );
              authZMockApi(token.sub)
                .then((response: any) => {
                  handleRedirectPage(
                    res.Data.redirectLink,
                    res.Data.wssCustomerID,
                    res.Data.contractId,
                    response,
                    res.Data?.cookie,
                  );
                  setIsLoginDisabled(false);
                })
                .catch(() => {
                  setIsLoginDisabled(false);
                  setIsLoginCredentialError(true);
                });
            } else authorizeLoginAndRedirect(res.Data);
          },
        )
        .catch((err) => {
          setIsLoginDisabled(false);
          handleApiError(err.response?.data?.StatusMsg);
        });
    }
  };
  const handleOnNavigateRegistration = (): void => {
    navigate(routes.RegistrationWss);
  };
  const handleNavigate = (): void => {
    navigate(routes.HelpCentre);
  };
  const handleOnGoBackBtn = (): void => {
    dispatch(LoginCredentialsAction(false));
    setIsLoginCredentialError(false);
    setIsWssDown(false);
    userName.current = '';
    password.current = '';
    setPasswordErrMsg('');
    setEmailErrMsg('');
  };
  const renderButton = (
    btnTxt: string,
    btnVariation?: ButtonProps['variation'],
    dataQaId?: string,
    handleButtonClick?: React.MouseEventHandler<HTMLElement>,
    testId?: string,
    buttonType = '',
  ): ReactElement => (
    <Button
      width="fluid"
      variation={btnVariation}
      onClick={handleButtonClick}
      data-qa-id={dataQaId}
      data-testid={testId}
      disabled={buttonType === 'login' && isLoginDisabled}
      icon={
        buttonType === 'login' && isLoginDisabled ? (
          <Spinner size="small" />
        ) : (
          <span />
        )
      }
      iconPosition="right"
    >
      {btnTxt}
    </Button>
  );
  const renderHeader = (): ReactElement => (
    <StyledWelcomeHeader>
      <BackgroundProvider value={{ cssValue: theme.config_header_on_dark }}>
        <Container>
          <Grid alignY="center">
            <GridItem xs={6} data-testid="Test-Heading">
              <Logo />
            </GridItem>
          </Grid>
        </Container>
      </BackgroundProvider>
    </StyledWelcomeHeader>
  );
  return (
    <StyledBackgroundImage>
      {renderHeader()}
      <StyledBoxContainer data-qa-id={dataQaIds.loginAndRegistration.container}>
        <StyledContentBox marginBottom={handleMarginBottom()}>
          {isLoginCredentials ? (
            <LoginCredentials
              handleOnLoginCredentialError={handleLoginCredentialError}
              handlePwd={handlePwd}
              handleUserName={handleUserName}
              isLoginCredentialError={isLoginCredentialError}
              emailFieldErrorMsg={emailErrMsg}
              handleOnEmailSetErrMsg={handleOnEmailSetErrMsg}
              handleOnPasswordSetErrMsg={handleOnPasswordSetErrMsg}
              passwordFieldErrorMsg={passwordErrMsg}
              emailId={userName.current}
              handleWssDown={handleWssDown}
              isWssDown={isWssDown}
            />
          ) : (
            <Heading size="s5" align="center" aria-level={4}>
              {welcomeMessage}
            </Heading>
          )}
          <Grid alignX={isDeviceFound.mobile ? 'center' : 'justify'}>
            <StyledLoginBtnGridItem xs={isDeviceFound.mobile ? 12 : 6}>
              {renderButton(
                registrationLabel,
                'primary',
                dataQaIds.loginAndRegistration.loginBtn,
                isLoginCredentials
                  ? handleOnLoginCredentials
                  : handleOnLoginBtn,
                'loginBtn',
                'login',
              )}
            </StyledLoginBtnGridItem>
            <GridItem xs={isDeviceFound.mobile ? 12 : 6}>
              {renderButton(
                isLoginCredentials ? goBackLabel : createAccountLabel,
                'secondary',
                dataQaIds.loginAndRegistration.createOnlineAccBtn,
                isLoginCredentials
                  ? handleOnGoBackBtn
                  : handleOnNavigateRegistration,
                'createOnlineAccBtn',
              )}
            </GridItem>
          </Grid>
          <Grid alignX="center">
            <ContentGroup marginBottom="none" marginTop="05">
              <LinkButton testId="needHelp" handleButtonClick={handleNavigate}>
                {needHelpText}
              </LinkButton>
            </ContentGroup>
          </Grid>
        </StyledContentBox>
      </StyledBoxContainer>
    </StyledBackgroundImage>
  );
}
